body {
  color: #2f2f41;
}

h1 {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
}

h2 {
  font-size: 35px;
  font-weight: bold;
}

.wrapper {
  max-width: 100%;
}

.header {
  color: #e5261f;
  background-color: #ffffff;
  //display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff6f8;
  padding: 10px 0px;
  text-decoration: none;
}

.logo {
  display: block;
}

.logo a {
  display: block;
  width: 100%;
  height: 100%;
  //font-weight: lighter;
  text-decoration: none!important;
  cursor: pointer;
}

a {
  color: #e5261f;
  text-decoration: none;
  background-color: transparent;
}

.site-header {
  border-top: 5px solid #FFFFFF !important;
}

.site-nav .page-link {
  color: #e5261f;
}

.row {
  display: flex;
  flex-wrap: wrap;
  //margin-right: -15px;
  //margin-left: -15px;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.intro {
  //padding-top: 140px;
  //padding-bottom: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
}

.col-lg-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-4 {
  flex: 0 0 32%;
  max-width: 32%;
}

.col-md-6 {
  flex: 0 0 45%;
  max-width: 45%;
}

.order-md-1 {
   order: 1;
}

.order-md-2 {
  order: 2;
}

.intro h1 {
  font-size: 50px;
  width: 80%;
  color: #2f2f41;
  font-weight: bold;
  line-height: 1.2;
}


.intro p {
  width: 80%;
}
.intro p {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #5c5a5a;
  font-family: Helvetica,Arial,sans-serif,-apple-system;
  margin-bottom: 20px;
  margin-top: 0;
}

.call {
  position: relative;
  overflow: hidden;
  background-color: #fbeaee;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 2;
}

.call .call-box-top {
  flex: 1 0 auto;
  padding: 20px;
}

.call .call-phone {
  margin-bottom: 5px;
}

.call .call-box-bottom {
  flex: 0 0 auto;
  border: none;
  padding: 20px;
}

.call-box-bottom a:visited {
  color: #ffffff;
}

.intro-image-hide-mobile {
  display: block;
}

.intro-image-absolute {
  width: 600px;
  max-width: none;
  padding: 0;
  //margin-bottom: 30px;
}

.intro-image {
  height: auto;
  margin-top: -40px;
}

img {
  vertical-align: middle;
  border-style: none;
}

.mb-1, .my-1 {
  margin-bottom: 10px !important;
}

p {
  line-height: 26px;
  color: #5c5a5a;
  font-family: Helvetica,Arial,sans-serif,-apple-system;
  margin-bottom: 20px;
}

.wrap {
  flex-wrap: wrap;
}

.button {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  background: #e5261f;
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.025em;
  color: #ffffff;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.button:hover {
  color: #ffffff;
  background-color: #ea524d;
  transform: translateY(-1px);
  text-decoration: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.color-red {
  color: #e5261f;
}

.tech-box {
  box-sizing: border-box;
  border: 1px solid;
  border-color: #e5e5e5;
  padding: 10px;
  margin: 5px;
}

.tech-box a {
  text-decoration: none;
  color: #1756a9;
}

.tech-box a > img {
  float: left;
}
.tech-box a div {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.tech-image {
  height: 40px;
  width: 40px;
}

.tech-desc-title {
  margin-bottom: 2px;
}

.tech-box-group :first-child {
  margin-left: 0px;
}

.tech-box-group :last-child {
  margin-right: 0px;
}

.services-header {
  font-size: 35px;
  color: #2f2f41;
}

.list {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  //max-width: 500px;
  margin-left: 0px;
}
.list-item {
  background-color: #ececec;
  display: flex;
  padding: 0.5em;
  margin-bottom: 20px;
}
.list-content {
  width: 100%;
}